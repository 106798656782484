import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?p=213

const EventPosledniBojovnik: FC = () => {
  return (
    <PageTemplate>
      <PostHeader
        title="POSLEDNÍ BOJOVNÍK"
        link="/event_posledni_bojovnik"
        author="Algy"
        date="18.03.2014"
        time="13:23"
        category="Eventy"
      />
      <p><strong>LAST MAN STANDING</strong></p>
      <p>1.<br />
        Tento PvP event se odehrává v aréně – nejčastěji colloseum u Adenu. Každý hráč se může eventu zúčastnit pouze s jednou postavou.<br />
        2.<br />
        Po přenesení do arény jsou před začátkem prvního kola hráči zbaveni všech buffů. Další kola již probíhají bez zbavení buffů.<br />
        3.<br />
        Hráči se rozestaví po stranách kolosea. Po té je jim dána možnost nabuffovat se vlastními buffy. Použití buff itemů (lahve, guny, svitky) je povoleno.<br />
        4.<br />
        Spolupráce hráčů mezi sebou je zakázána a bude potrestána diskvalifikací, případně i dalším trestem. Za spolupráci se považuje např. vzájemné buffování či léčení; taktická spolupráce zakázana není.<br />
        5.<br />
        Buff Salvation a jemu podobné jsou v průběhu eventu zakázány.<br />
        6.<br />
        Na povel GM se mohou hráči napadat a soupeřit mezi sebou.<br />
        7.<br />
        Bitevní kolo vyhrává ten hráč, který jako jediný přežije klání. Event vyhrává ten, kdo získá jako první vítězství ve dvou bitevních kolech.<br />
        8.<br />
        Opuštění bitevní zóny v průběhu bitevního kola nebo opuštění prostor collosea v průběhu eventu je kontumační prohrou v celém eventu.<br />
        9.<br />
        Hráči se oživí až na povel GM. Doplňování CP u NPC v colloseu je povoleno.<br />
        10.<br />
        Ceny v eventu určuje GM podle počtu soupeřících hráčů a zpravidla se liší pro 1-5 hráčů, pro 6-10 hráčů a pro 11+ hráčů.</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>

      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default EventPosledniBojovnik

export { Head } from '../components/defaultHtmlHead'